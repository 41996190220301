import React from 'react';
import { Avatar, Box, Timeline, Spinner, IconButton } from '@primer/react';
import { Octicon } from '@primer/react';
import { GitCommitIcon, LinkIcon } from '@primer/octicons-react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ArrowSquareOut } from 'phosphor-react';

dayjs.extend(relativeTime);

interface CommitsTimelineProps {
  commits: any;
  isLoading: boolean;
  searchTerm: string;
}

const CommitsTimeline: React.FC<CommitsTimelineProps> = ({ commits, isLoading, searchTerm }) => {
  const filteredCommits = commits?.filter((commit: any) =>
    commit?.commit?.message?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return (
      <Box className="flex justify-center my-10 items-center">
        <Spinner size="medium" />
      </Box>
    );
  }

  return (
    <Box className="overflow-y-auto max-h-[405px] px-3">
      {filteredCommits.length === 0 ? (
        <Box className="flex justify-center items-center p-4">
          <p className="text-sm text-gray-500">No changes found with that name.</p>
        </Box>
      ) : (
        <Timeline>
          {filteredCommits.map((commit: any) => (
            <Timeline.Item key={commit?.sha}>
              <Timeline.Badge>
                <Octicon icon={GitCommitIcon} />
              </Timeline.Badge>
              <Timeline.Body className="flex justify-between">
                <Box className="flex flex-col justify-start items-start gap-2">
                  <h3 className="text-base font-semibold">{commit?.commit?.message}</h3>
                  <Box className="flex gap-1 items-center text-sm font-normal">
                    <Avatar src={commit?.committer?.avatar_url} size={20} />
                    <p>{commit?.committer?.login}</p>
                    updated it
                    <p>{dayjs(commit?.commit?.author?.date).fromNow()}</p>
                  </Box>
                </Box>
                <Box className="flex items-center gap-2">
                  <IconButton
                    aria-label="See changes"
                    icon={ArrowSquareOut}
                    onClick={() => window.open(`${commit?.html_url}?diff=split`, '_blank')}
                    variant="invisible"
                    sx={{
                      fontSize: '18px',
                    }}
                  />
                </Box>
              </Timeline.Body>
            </Timeline.Item>
          ))}
        </Timeline>
      )}
    </Box>
  );
};

export default CommitsTimeline;
