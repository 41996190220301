import { gql } from "@apollo/client";

export const DELETE_FOLDER = gql`
  mutation DeleteFolder($input: CreateCommitOnBranchInput!) {
    createCommitOnBranch(input: $input) {
      commit {
        oid
        message
      }
    }
  }
`;