import { gql } from '@apollo/client';

// Mutation to create a commit on a branch
export const CREATE_COMMIT_ON_BRANCH = gql`
  mutation CreateCommitOnBranch($input: CreateCommitOnBranchInput!) {
    createCommitOnBranch(input: $input) {
      commit {
        url
        oid
      }
    }
  }
`;

// Query to get the list of files in a folder
export const GET_FILES_IN_FOLDER = gql`
  query($owner: String!, $name: String!, $expression: String!) {
    repository(owner: $owner, name: $name) {
      object(expression: $expression) {
        ... on Tree {
          entries {
            name
            type
            path
          }
        }
      }
    }
  }
`;

// Query to get file content
export const GET_FILE_CONTENT = gql`
  query($owner: String!, $name: String!, $expression: String!) {
    repository(owner: $owner, name: $name) {
      object(expression: $expression) {
        ... on Blob {
          text
        }
      }
    }
  }
`;
