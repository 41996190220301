import { gql } from '@apollo/client';

export const GET_LATEST_COMMIT = gql`
  query($owner: String!, $name: String!, $qualifiedName: String!) {
    repository(owner: $owner, name: $name) {
      ref(qualifiedName: $qualifiedName) {
        target {
          ... on Commit {
            oid
          }
        }
      }
    }
  }
`;