import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box } from '@primer/react';
import { useAppContext } from '@/context/AppContext';
import { useGetUserDetails } from '@/features/auth';
import { useGetRepoDetailsById } from '@/features/repositories';
import { Head } from '../Head';
import LeftSidebar from './LeftSidebar';
import { TopBar } from './TopBar';
import { useTeamCreditsStore } from '@/stores/teamCredits';
import { useGetFlowscaleUser } from '@/features/users';
import ModalLogo from '@/assets/modal.svg';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { data: userDetails, refetch } = useGetUserDetails();
  const { data: user } = useGetFlowscaleUser();

  const { activeTabModule, setActiveTabModule } = useAppContext();
  const { credits } = useTeamCreditsStore();

  const location = useLocation();
  const showLogoWithFullName =
    location.pathname.includes('/app/repositories') &&
    !location.pathname.includes('/app/repositories/');

  const hideOnProfilePage = location.pathname.includes('/app/profile');

  const { projectId } = useParams<{ projectId: any }>();
  const { data: repoDetails } = useGetRepoDetailsById(projectId || '');

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <Box className="h-screen flex flex-col overflow-hidden bg-[#16191D]">
      <Head
        title={
          activeTabModule === 'profile' && !showLogoWithFullName
            ? 'Profile'
            : activeTabModule !== 'profile'
            ? repoDetails?.name
            : null
        }
      />

      <TopBar userDetails={userDetails} repoDetails={repoDetails} userCredits={credits} />

      <Box className="flex h-full overflow-hidden">
        {!showLogoWithFullName && !hideOnProfilePage && (
          <LeftSidebar
            activeTab={activeTabModule}
            setActiveTab={setActiveTabModule}
            userDetails={userDetails || user}
          />
        )}

        <main className="flex-1 relative flex-grow h-full focus:outline-none">{children}</main>
      </Box>
      {showLogoWithFullName ? (
        <Box className="flex justify-end border-t border-[#22262C] px-[120px] pb-1.5 pt-1">
          <span
            onClick={() => {
              window.open('https://modal.com/', '_blank');
            }}
            className="text-sm text-gray-500 flex items-center gap-2 cursor-pointer"
          >
            Powered by <img src={ModalLogo} className="h-3" alt="modal-logo" />
          </span>
        </Box>
      ) : null}
    </Box>
  );
};
